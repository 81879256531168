import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import { getApi, creatorLogin, BOOKPAGE_URL } from '../utilFiles/mdbRoomInfo';

import { withStyles } from '@material-ui/core/styles';
import isElectron from 'is-electron';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import CookieConsent from 'react-cookie-consent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { store } from '../store';
import * as meActions from '../actions/meActions';

const styles = (theme) =>
	({
		root :
		{
			display              : 'flex',
			width                : '100%',
			height               : '100%',
			backgroundColor      : 'var(--background-color)',
			backgroundImage      : `url(${window.config ? window.config.background : null})`,
			backgroundAttachment : 'fixed',
			backgroundPosition   : 'center',
			backgroundSize       : 'cover',
			backgroundRepeat     : 'no-repeat'
		},
		dialogTitle :
		{
		},
		dialogPaper :
		{
			width                          : '30vw',
			maxHeight                      : '100%',
			// padding                        : theme.spacing(2),
			[theme.breakpoints.down('lg')] :
			{
				width : '40vw'
			},
			[theme.breakpoints.down('md')] :
			{
				width : '50vw'
			},
			[theme.breakpoints.down('sm')] :
			{
				width : '70vw'
			},
			[theme.breakpoints.down('xs')] :
			{
				width : '90vw'
			}
		},
		logo :
		{
			display       : 'block',
			paddingBottom : '1vh'
		},
		loginButton :
		{
			position : 'absolute',
			right    : theme.spacing(2),
			top      : theme.spacing(2),
			padding  : 0
		},
		largeIcon :
		{
			fontSize : '2em'
		},
		largeAvatar :
		{
			width  : 50,
			height : 50
		},
		green :
		{
			color : 'rgba(0, 153, 0, 1)'
		},
		roomName :
		{
			fontWeight : 'bolder',
			marginLeft : theme.spacing(1)
		},
		dialogTopBar : {
			display        : 'flex',
			justifyContent : 'space-between',
			// marginBottom   : '1rem',
			fontWeight     : 'bolder',
			fontSize       : '1rem'
		},
		dialogTopBarRoomLink : {
			'&:hover' : {
				fontWeight     : 'bold',
				textDecoration : 'underline 3px'
			}
		},
		dialogTopBarBookRoomLink : {
			display    : 'flex',
			alignItems : 'center',
			cursor     : 'pointer',
			'&:hover'  : {
				fontWeight     : 'bold',
				textDecoration : 'underline 3px'
			}
		},
		dialogTopBarBookRoomLinkIcon : {
			marginLeft : '0.25rem'
		}
	});

const DialogTitle = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
	root :
	{
		padding : theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogActions);

const CreatorLogin = ({ classes }) =>
{
	const curUrl = new URL(window.location.href);
	const roomidParam = curUrl.searchParams.get('roomid');
	const roomPageLink = `/${roomidParam}`;
	// const createRoomPageLink = 'https://bookremeet.watakura.xyz/';

	const intl = useIntl();
	const history = useHistory();

	const [ isLoading, setIsLoading ] = useState(false);
	const [ creatorEmailInput, setCreatorEmailInput ] = useState('');
	const [ passwordInput, setPasswordInput ] = useState('');
	const [ creatorPassInput, setCreatorPassInput ] = useState('');
	const [ creatorLoginError, setCreatorLoginError ] = useState('');
	const [ showPassword, setShowPassword ] = useState(false);

	const creatorLoginHandler = () =>
	{
		setIsLoading(true);
		localStorage.removeItem('creatorLoginInfo');

		getApi()
			.then((res) =>
			{
				// eslint-disable-next-line no-console
				console.log(res);

				// return creatorLogin(props.roomId, creatorEmailInput, creatorPassInput);
				return creatorLogin(
					roomidParam,
					creatorEmailInput,
					passwordInput,
					creatorPassInput
				);
			})
			.then((res) =>
			{
				// eslint-disable-next-line no-console
				console.log(res);

				// // throw error now is more then 10mins before start. 
				if (res.data.start - 1000*60*10 > Date.now())
				{
					// eslint-disable-next-line no-console
					console.log(res.data.start- 1000*60*10, Date.now());
					throw new Error('Too early to enter room');
				}

				// // store creator info in ls set creator state
				localStorage.setItem('creatorLoginInfo', JSON.stringify(res.data));
				localStorage.setItem('loginkind', 'creator');
				// store.dispatch(meActions.setRoomCreator(true));

				setIsLoading(false);

				history.push(`/${roomidParam}?loginkind=creator`);
				// history.replace(`/${roomidParam}?loginkind=creator`);
			})
			.catch((err) =>
			{
				// eslint-disable-next-line no-console
				console.log(err);

				if (err.message === 'get-api-failed')
				{
					setCreatorLoginError(
						intl.formatMessage({
							id             : 'room.getApiError',
							defaultMessage : 'Service is not available now.'
						})
					);
				}
				else
				{
					setCreatorLoginError('Login failed. Please make sure Room Name, Password, and creatorPass in Email');
					setTimeout(() => { setCreatorLoginError(''); }, 1000*10);
				}

				setIsLoading(false);
			});
	};

	const creatorLogoutHandler = () =>
	{
		localStorage.removeItem('creatorLoginInfo');
	};

	return (
		<div>

			<div className={classes.root}>

				<Dialog
					open
					classes={{
						paper : classes.dialogPaper
					}}
				>
					<DialogTitle>
						<div className={classes.dialogTopBar}>
							<div className={classes.dialogTopBarRoomLink}>
								<Link to={roomPageLink}>
									{/* room page */}
									{intl.formatMessage({
										id             : 'label.roomPageLink',
										defaultMessage : 'Room page'
									})}
								</Link>
							</div>
							<div className={classes.dialogTopBarBookRoomLink}>
								<a href={BOOKPAGE_URL} target='_blank' rel='noopener noreferrer'>
									{intl.formatMessage({
										id             : 'label.createPageLink',
										defaultMessage : 'Create new room'
									})}
								</a>
								<OpenInNewIcon
									className={classes.dialogTopBarBookRoomLinkIcon}
								/>
							</div>
						</div>
						{/* <hr /> */}
					</DialogTitle>

					<DialogTitle>
						{/* { window.config.logo !=='' ?
							<img alt='Logo' src={window.config.logo} /> :
							<Typography variant='h5'> {window.config.title} </Typography>
						} */}
						{/* <div>
							{intl.formatMessage({
								id             : 'label.creatorLoginTitle',
								defaultMessage : 'Room Creator Login Page'
							})}
						</div> */}
						{/* <hr /> */}
					</DialogTitle>

					{/* <form method='post' action='/auth/callback'> */}
					<DialogContent>
						<div>
							{intl.formatMessage({
								id             : 'label.roomName',
								defaultMessage : 'Room name'
							})}:
							<span className={classes.roomName}>{roomidParam}</span>
						</div>
						<TextField
							id='password'
							// label={intl.formatMessage({
							// 	id             : 'label.password',
							// 	defaultMessage : 'Password'
							// })}
							label='Password'
							variant='outlined'
							margin='normal'
							name='password'
							type={!showPassword ? 'password' : ''}
							required
							fullWidth
							value={passwordInput}
							onChange={(event) => { setPasswordInput(event.target.value); }}
						/>
						<div>
							<Checkbox
							// defaultChecked
								onChange={() => { setShowPassword(!showPassword); }}
								color='default'
								inputProps={{ 'aria-label': 'checkbox with default color' }}
							/>
							<span>
								{intl.formatMessage({
									id             : 'label.showPassword',
									defaultMessage : 'display password input'
								})}
							</span>
						</div>
						{/* <TextField
							autoFocus
							id='email'
							// label={intl.formatMessage({
							// 	id             : 'label.username',
							// 	defaultMessage : 'Username'
							// })}
							label='Email'
							variant='outlined'
							margin='normal'
							name='username'
							required
							fullWidth
							value={creatorEmailInput}
							onChange={(event) => { setCreatorEmailInput(event.target.value); }}
						/> */}
						<TextField
							id='creatorPass'
							// label={intl.formatMessage({
							// 	id             : 'label.password',
							// 	defaultMessage : 'Password'
							// })}
							label='creatorPass'
							variant='outlined'
							margin='normal'
							name='creatorPass'
							type='text'
							required
							fullWidth
							value={creatorPassInput}
							onChange={(event) => { setCreatorPassInput(event.target.value); }}
						/>

					</DialogContent>

					<DialogActions>
						<Button
							variant='contained'
							color='secondary'
							type='submit'
							disabled={isLoading}
							onClick={() => { creatorLoginHandler(); }}
						>
							<FormattedMessage
								id='label.login'
								defaultMessage='Login'
							/>
						</Button>
					</DialogActions>
					{/* </form> */}

					<div>
						{isLoading ? (
							<Box sx={{ display: 'flex' }}>
								<CircularProgress disableShrink />
							</Box>
						)
							: null
						}
					</div>
					<div>
						{creatorLoginError}
					</div>

					{ !isElectron() &&
					<CookieConsent buttonText={intl.formatMessage({
						id             : 'room.consentUnderstand',
						defaultMessage : 'I understand'
					})}
					>
						<FormattedMessage
							id='room.cookieConsent'
							defaultMessage='This website uses cookies to enhance the user experience'
						/>
					</CookieConsent>
					}
				</Dialog>
			</div>
		</div>
	);
};

CreatorLogin.propTypes =
{
	classes : PropTypes.object.isRequired
};

// export default CreatorLogin;
export default withStyles(styles)(CreatorLogin);