import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useIntl, FormattedMessage } from 'react-intl';

import Image1 from '../images/webinar-cartoon.png';
import Image2 from '../images/edumeet-pic1.png';
import RemeetImage1 from '../images/remeet-image-1.jpg';
import RemeetImage3 from '../images/remeet-image-3.png';

// const Room = ReactLazyPreload(() => import(/* webpackChunkName: "room" */ './Room'));
const styles = (theme) =>
	({
		root :
		{
			padding    : '1rem',
			fontWeight : 'bold',
			fontSize   : '1.1rem',
			width      : '100%',
			maxWidth   : '40rem'
		},
		title : {
			textAlign : 'center'
		}
	});

const AboutAppContent = (props) =>
{
	const { classes } = props;

	const intl = useIntl();

	let imageContainerStyle = {
		display        : 'flex',
		flexDirection  : 'column',
		flexWrap       : 'wrap',
		justifyContent : 'center',
		gap            : '2rem',
		paddingTop     : '1rem'
	};

	let imageStyle = {
		width  : '100%',
		height : '100%'
	};

	if (window.innerWidth > 480)
	{
		imageContainerStyle ={
			...imageContainerStyle,
			flexDirection : 'row'
		};

		imageStyle = {
			width  : '200px',
			height : '200px'
		};
	}

	return (
		<Fragment>
			<div className={classes.root}>
				<h3 className={classes.title}>
					{/* {intl.formatMessage({
						id             : 'aboutApp.text01',
						defaultMessage : 'About REMEET'
					})} */}
					REMEET
				</h3>
				<p>
					{intl.formatMessage({
						id             : 'aboutApp.text02',
						defaultMessage : 'REMEET is a simple video meeting and talk application. It contains text chat, screen sharing, file sharing.'
					})}
				</p>

				<p>
					{intl.formatMessage({
						id             : 'aboutApp.text03',
						defaultMessage : 'Users of REMEET can communicate with multiple people at the same time.'
					})}
					{' '}
					{intl.formatMessage({
						id             : 'aboutApp.text04',
						defaultMessage : 'That is suitable for various situations such as talking with remote people, remote work, and remote meeting.'
					})}
				</p>
				<p>
					{intl.formatMessage({
						id             : 'aboutApp.text05',
						defaultMessage : 'Up to two participants, users can start video talk any time,'
					})}
					{' '}
					{intl.formatMessage({
						id             : 'aboutApp.text06',
						defaultMessage : 'after room reservation at'
					})}
					{' '}
					 <a target='_blank' rel='noopener noreferrer' href='https://bookremeet.spaceeight.net'>
						{intl.formatMessage({
							id             : 'aboutApp.text09',
							defaultMessage : 'room reservation page'
						})}
					</a>.
				</p>
				<p>
					{intl.formatMessage({
						id             : 'aboutApp.text07',
						defaultMessage : 'For more than three participants,'
					})}
					{' '}
					<a target='_blank' rel='noopener noreferrer' href='https://bookremeet.spaceeight.net/trial-information'>
						{intl.formatMessage({
							id             : 'aboutApp.text08',
							defaultMessage : 'there is free trial period for 30 days'
						})}</a>.
				</p>

				<div
					style={imageContainerStyle}
				>
					<img style={imageStyle} src={RemeetImage1} />
					<img style={imageStyle} src={RemeetImage3} />
				</div>

			</div>
		</Fragment>
	);
};

AboutAppContent.propTypes =
{
	room    : PropTypes.object.isRequired,
	classes : PropTypes.object.isRequired
};

const mapStateToProps = (state) =>
	({
		room : state.room
	});

export default connect(
	mapStateToProps,
	null,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room === next.room
			);
		}
	}
// )(AboutAppContent);
)(withStyles(styles)(AboutAppContent));