require('dotenv').config();

//
//
// // for deploy
export const BOOKPAGE_URL = process.env.REACT_APP_BOOKPAGE_URL;
const URL = process.env.REACT_APP_NODEJS_URL;

//
//
// // for loacal dev
// export const BOOKPAGE_URL = process.env.REACT_APP_DEV_BOOKPAGE_URL;
// const URL = process.env.REACT_APP_DEV_NODEJS_URL;

//
//

export const getApi = () =>
{
	return new Promise((resolve, reject) =>
	{
		fetch(`${URL}/example/`)
			.then((res) =>
			{
				if (res.status !== 200 && res.status !== 201)
				{
					throw new Error('get api failed!');
				}

				return res.json();
			})
			.then((resData) =>
			{
				resolve({ message: 'get api success', data: resData.data });
			})
			.catch((err) =>
			{
				reject({ message: 'get-api-failed', error: err });
			});
	});

};

export const checkRoom = (roomId, password) =>
{
	return new Promise((resolve, reject) =>
	{
		fetch(`${URL}/room/check-room`, {
		// fetch('/room/check-room', {
			method  : 'POST',
			headers : {
				// Authorization: 'Bearer ' + token,
				'Content-Type' : 'application/json'
			},
			body : JSON.stringify({
				roomId   : roomId,
				password : password
			})
		})
			.then((res) =>
			{
				if (res.status !== 200 && res.status !== 201)
				{
					throw new Error('get room info failed!');
				}

				return res.json();
			})
			.then((resData) =>
			{
				resolve({ message: 'get room info success', data: resData.data });
			})
			.catch((err) =>
			{
				reject({ message: 'get-room-info-failed', error: err });
			});
	});

};

export const creatorLogin = (roomId, email, password, creatorPass) =>
{
	return new Promise((resolve, reject) =>
	{
		fetch(`${URL}/room/get-room`, {
		// fetch('/room/check-room', {
			method  : 'POST',
			headers : {
				// Authorization: 'Bearer ' + token,
				'Content-Type' : 'application/json'
			},
			body : JSON.stringify({
				roomId      : roomId,
				email       : email,
				password    : password,
				creatorPass : creatorPass
			})
		})
			.then((res) =>
			{
				if (res.status !== 200 && res.status !== 201)
				{
					throw new Error('creator login failed!');
				}

				return res.json();
			})
			.then((resData) =>
			{
				resolve({ message: 'creator logging in success', data: resData.data });
			})
			.catch((err) =>
			{

				reject({ message: 'creator-login-failed', error: err });
			});
	});

};

export const updatePeopleNums = (
	roomId,
	password,
	peersLength,
	action,
	meId,
	getAt
) =>
{
	return new Promise((resolve, reject) =>
	{
		fetch(`${URL}/meeting/update-people-nums`, {
			// fetch('/room/check-room', {
			method  : 'PUT',
			headers : {
				// Authorization: 'Bearer ' + token,
				'Content-Type' : 'application/json'
			},
			body : JSON.stringify({
				roomId      : roomId,
				password    : password,
				peersLength : peersLength,
				action      : action,
				meId        : meId,
				getAt       : getAt
			})
		})
			.then((res) =>
			{
				if (res.ok)
				{
					return res.json();
				}

				// // error handling
				return res.json().then((err) =>
				{
					// throw new Error(data.message || 'Something went wrong')

					if (err.data)
					{
						reject({
							message   : err.message || 'Something went wrong',
							errorType : err.data.errorType
							// error: err
						});
					}

					reject({
						message : err.message || 'Something went wrong'
						// type: "get-api-failed",
						// error: err
					});
				});
			})
			.then((resData) =>
			{
				resolve(resData);
			})
			.catch((err) =>
			{
				reject(err);
			});
	});

};

export const getStripeCustomer = (emailAddr, token) =>
{
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) =>
	{
		const { customer, error } = await fetch(`${URL }/customer/retrieve-email-customer-meeting`, {
			method  : 'post',
			headers : {
				Authorization  : `Bearer ${ token}`,
				'Content-Type' : 'application/json'
			},
			body : JSON.stringify({
				email : emailAddr
			})
		})
			.then((r) =>
			{
				return r.json();
			});

		if (customer)
		{
			// localStorage.setItem('customerId', customer.id);
			// localStorage.setItem('customerEmail', customer.email);

			resolve(customer);
		}
		if (error)
		{
			reject(error);
		}
	});
};

export const getCustomerSubscriptions = (customerId, token, email) =>
{
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) =>
	{
		// const {subscriptions} = await fetch(BACKEND_URL + '/subscriptions').then(r => r.json());
		const { subscriptions, error } = await fetch(`${URL}/customer/list-customer-subscriptions-meeting`, {
			method  : 'post',
			headers : {
				Authorization  : `Bearer ${ token}`,
				'Content-Type' : 'application/json'
			},
			body : JSON.stringify({
				customerId : customerId,
				email      : email
			})
		}).then((r) => r.json());

		resolve(subscriptions);

		if (error)
		{
			reject(error);
		}
		// if (subscriptions) {
		// }
	});
};

export const getCustomerDataSubscriptions = (token, email) =>
{
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) =>
	{
		getStripeCustomer(email, token)
			.then((result) =>
			{
				return getCustomerSubscriptions(result.id, token, email);
			})
			.then((subResult) =>
			{
				resolve(subResult);
			})
			.catch((err) =>
			{
				reject(err);
			});
	});
};

export const getCurrentSubscription = (subscriptions) =>
{
	let currentSub;

	if (subscriptions.length > 0)
	{
		const subs = [];

		for (const subData of subscriptions)
		{
			if (
				subData.status === 'active'
        || subData.status === 'past_due'
        || subData.status === 'unpaid'
        || subData.status === 'trialing'
			)
			{
				subs.push(subData);
			}
		}

		if (subs.length > 0)
		{
			currentSub = subs[0];
		}

		return currentSub;
	}
};