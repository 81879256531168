// export function getSignalingUrl(peerId, roomId)
export function getSignalingUrl(peerId, roomId, rcemail, rcpass)
{
	// const port =
	// 	process.env.NODE_ENV !== 'production' ?
	// 		window.config.developmentPort
	// 		:
	// 		window.config.productionPort;
	// const port = 3000;

	// const url = `wss://localhost:${port}/?peerId=${peerId}&roomId=${roomId}`;
	// const url = `wss://${window.location.hostname}:${port}/?peerId=${peerId}&roomId=${roomId}`;

	// //for local dev
	// const port = 3443;
	// const url = `wss://${window.location.hostname}:${port}/?peerId=${peerId}&roomId=${roomId}`;

	// //for deploy
	const port = 443;
	// const url = `wss://${process.env.REACT_APP_SERVER_ADDRESS}:${port}/?peerId=${peerId}&roomId=${roomId}`;
	const url = `wss://${process.env.REACT_APP_SERVER_ADDRESS}:${port}/?peerId=${peerId}&roomId=${roomId}&rcemail=${rcemail}&rcpass=${rcpass}`;

	return url;
}
